import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col, Input, Navbar,NavbarBrand, NavbarToggler, Collapse,Nav, NavItem,NavLink, Spinner } from 'reactstrap';
import { useEffect, useRef } from 'react';
import { w3cwebsocket as W3CWebSocket } from "websocket";

const WSS = "wss://gpt.scalingapi.com/wss";

function App() {

  const divRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [question, setQuestion] = useState("");
  const [history, setHistory] = useState([]);
  const [client, setClient] = useState(null);
  const [input, setInput] = useState("");
  const [response, setResponse] = useState("");
  const [messageSent, setMessageSent] = useState(false);

  useEffect(() => {
    const newClient = new W3CWebSocket(WSS);
    setClient(newClient);

    newClient.onopen = () => {
      alert(`El chatbot está activo.`);
    };

    newClient.onmessage = (message) => {
      if (message.data !== 'PING') {
        setResponse(message.data);
      }
    };

    newClient.onerror = (error) => {
      alert("Error "+JSON.stringify(error));
    };

    return () => {
      newClient.close();
    };
  }, []);

  useEffect(() => {
    if(response !== "" && response !== null)
    {
      var respLines = response.split("\n");

      setHistory([...history, {"actor": "bot", "text": respLines}]);
      setMessageSent(false);
    }
  },[response]);

  /*useEffect(() => {
    if (input !== "") {
      client.send(input);
    }
  }, [response]);
*/
  
  useEffect(() => {
    const divElement = divRef.current;
    if (divElement !== null) {
      divElement.scrollTop = divElement.scrollHeight; }
  }, [history]);

  const toggle = () => setIsOpen(!isOpen);

  const getQuestion = (text) => { setQuestion(text); }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setHistory([...history, {"actor": "user", "text": question}]); // Updated this line
      client.send(question);
      setQuestion("");
      setMessageSent(true);
    }
  }

  return (
    <div>
      <Navbar expand="sm" color="dark" dark style={{minHeight: "50px"}}>
        <NavbarBrand href="/">GPT ScalingAPI</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink href="/components/">Reset</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <Container>
        <div ref={divRef} style={{ overflowY: "auto", overflowX: "hidden", height: "calc(100vh - 100px)" }}>
          {history.map((value, index) => 
            <Row key={index} className="py-4" style={{backgroundColor: (value["actor"]==="bot")?"#ffffe6":"#e6ffff"}}>
              <Col sm="1"><center><img src={(value["actor"]==="bot")?"robot.svg":"persona.svg"} height="30px" width="30px"/></center></Col>
              <Col sm="11">{
                (value["actor"]==="bot")?
                Object.values(value["text"]).map((line) => <>{line}<br></br></>)
                :
                <>{value["text"]}</>
              }</Col>
            </Row>
          )}
          { (messageSent === true)?
            <Row key="waiting" className="py-4" style={{backgroundColor: "#ffffe6"}}>
              <Col sm="1"><center><img src="robot.svg" height="30px" width="30px"/></center></Col>
              <Col sm="11"><Spinner size="sm" type="grow"></Spinner><Spinner size="sm" type="grow"></Spinner><Spinner size="sm" type="grow"></Spinner></Col>
            </Row>
            :
            <></>
          }
        </div>
      </Container>
      <Container>
          <Input type="text" value={question} placeholder="Write your question here" onChange={(e) => getQuestion(e.target.value)} onKeyDown={handleKeyDown}/>
      </Container>
    </div>
  );
}

export default App;
